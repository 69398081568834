<template>
  <form>
    <b-card-code title="Upload DDA">

      <b-row>
        <b-col md="6" xl="5" class="mb-1">
          <b-form-group label="Arquivo:" >
             <b-form-file
                truncate-length="50"
                accept=".ret"
                placeholder="Selecione o arquivo..."
                @change="onChangeDoc"
                ref="DocInput"
              ></b-form-file>
          </b-form-group>
        </b-col>

        <b-col md="6" xl="4" class="mb-1">
              <b-form-group label="Config.:"  >
                  <multiselect
                    v-model="RETCOD"
                    placeholder="Selecione o modelo"
                    label="name"
                    track-by="code"
                    :options="RETCODOption"
                    :multiple="false"
                    :taggable="false"
                    :close-on-select="true"
                    :option-height="50"
                    :show-labels="false"
                  ></multiselect>
              </b-form-group>
            </b-col>
       

      </b-row>

 
    </b-card-code>


    <b-card-code v-if="PermiteExecutar">
      <b-row>
        <b-col md="6" xl="4" class="mb-1">
          <div class="DivBotaoLadoLado">
            <b-button variant="warning" @click="ExecutarImportacao()" >
              <span>EXECUTAR IMPORTAÇÃO</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card-code>
  </form>
</template>
<script>
import Multiselect from 'vue-multiselect'
import BCardCode from '@core/components/b-card-code'
import {
  BFormInput, BRow, BCol, BFormGroup, BFormTextarea, BFormDatepicker, BButton,
  BTable, BFormSelect, BPagination,
  BCardBody, BFormFile,
} from 'bootstrap-vue'
import apiPortalSky from '@/services/apiPortalSky'
import AlertaMsg from '@/customPortal/AlertaMsg.vue'
import ConexaoAPI from '@/customPortal/ConexaoAPI.vue'
import UtilVUE from '@/customPortal/UtilVUE.vue'
import Spinner from '@/components/Spinner.vue'

export default {
  components: {
    BCardCode,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BFormTextarea,
    BFormDatepicker,
    BButton,
    BTable,
    BFormSelect,
    BPagination,
    BCardBody,
    BFormFile,
    Multiselect,
  },
  data() {
    return {
      RETCOD: [],
      RETCODOption: [],
      PermiteExecutar: false,
      DocDDA: [
        {ddaimpid: ''},
        {sequencia: 0},
        {registro: ''}
      ],
    }
  },
  methods: {
    async ExecutarImportacao() {
      Spinner.StarLoad()
      this.PermiteExecutar = false
      let notification = ''
      let MsgErro = ''
     
      let pRETCOD = ''

      if(this.RETCOD != null){
         if(Array.isArray(this.RETCOD) == false){
             pRETCOD = this.RETCOD.code
         }
      }


      const usuario = this.$session.get('usuario')
      const URLpost = `/DDA/UploadDDA?CODUSU=${usuario.codusu}&RETCOD=${pRETCOD}`
          
       const ParametroJson = JSON.stringify(this.DocDDA)

        await apiPortalSky.post(URLpost, ParametroJson, {
          headers: {
            'Content-Type': 'application/json',
          },
        }).then(response => {
          if (response.status === 200) {
            this.DocDDA = []
            notification = { message: "Atualização Realizada com Sucesso", alert_type: 'success' }
            this.$session.set('notification', this.notification)
            this.$router.push('../matchDDA')
           
          } else{
            notification = { message: "Erro ao Importar o DDA", alert_type: 'error' }
          }
        })
        .catch(error => {
            notification = ConexaoAPI.ValidaErro(error)
          })


       if(notification !== '') {
          AlertaMsg.FormataMsg(notification)
       }else{
          Spinner.EndLoad()
       }

    },
    async onChangeDoc(e) {
     let notification = ''
     let MsgErro = ''
     Spinner.StarLoad()

       this.DocDDA = []
       
       try{
           this.PermiteExecutar = false
           let TabTXT = await UtilVUE.ReadTXT(e,"Arry")
           //this.$refs['DocInput'].reset()
           
          if(TabTXT != null){
              if(TabTXT.length == 0){
                  MsgErro = `Documento Enviado não foi Carregado. Por favor valide o arquivo e tente novamente.`
                  notification = { message: MsgErro, alert_type: 'error' }
              }else{
                this.CarregaDocDDA(TabTXT)
                this.PermiteExecutar = true
              }
           } else{
            MsgErro = `Documento Enviado não foi Carregado. Por favor valide o arquivo e tente novamente.`
            notification = { message: MsgErro, alert_type: 'error' }
           }
      }
      catch (error)
      {
        MsgErro = error
        notification = { message: MsgErro, alert_type: 'error' }
      }

       if(notification !== '') {
          AlertaMsg.FormataMsg(notification)
       }else{
          Spinner.EndLoad()
       }

    },
    CarregaDocDDA(TabTXT){

      for (let sequencia in TabTXT) {         
                 
           let Dados = {
            ddaimpid: '',
            sequencia: parseInt(sequencia)+1,
            registro: TabTXT[sequencia],   
            }         
          this.DocDDA.push(Dados)

         }
    },
    async ConsultarOpcoes() {
      let notification = ""
      Spinner.StarLoad()
      const usuario = this.$session.get('usuario')
      
      let ParametroJson = ""
      let URLpost = `/Opcoes/ConsultaOpcoes?CODUSU=${usuario.codusu}&OPCAO=DDA_RETCOD`
      await apiPortalSky.post(URLpost, ParametroJson).then(response => {
        if (response.status === 200) {
          if (response.data.length > 0) {
            this.RETCODOption = response.data
            this.RETCOD = this.RETCODOption[0]
          } else {
            notification = ConexaoAPI.MsgFiltroSemRetorno()
            AlertaMsg.FormataMsg(notification)
          }
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
      })


      if(notification === '') {
       Spinner.EndLoad()
      }

    },
  },
  mounted() {
    this.ConsultarOpcoes()
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>